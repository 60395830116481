<!--
 * @Description: 
 * @Author: 
 * @Date: 2023-03-06 09:46:37
 * @LastEditors: koushaoping 1719422019@qq.com
 * @LastEditTime: 2023-10-23 10:22:50
-->
<template>
  <div class="editDialog">
    <el-button type="text"  @click="edit(roleId)">编辑</el-button>

    <!-- 修改角色的对话框 -->
    <el-dialog
            title="编辑液厂企业"
            :visible.sync="editDialogVisible"
            width="50%">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
        <el-form-item label="企业名称" prop="factoryName">
          <el-input v-model="ruleForm.factoryName" placeholder="请输入企业名称" ></el-input>
        </el-form-item>
        <el-form-item label="统一社会代码" prop="unifiedSocialCode">
          <el-input v-model="ruleForm.unifiedSocialCode" placeholder="请输入统一社会代码" ></el-input>
        </el-form-item>
        <el-form-item label="管理员" prop="adminName">
          <el-input v-model="ruleForm.adminName" placeholder="请输入管理员名称" ></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="adminPhone">
          <el-input v-model="ruleForm.adminPhone" placeholder="请输入联系电话" readonly></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="adminPassword">
          <el-input v-model="ruleForm.adminPassword" placeholder="请输入密码" ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editRole">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { editManufacturerRequest } from 'network/customer'

  export default {
    name: "ManufacturerEdit",
    props: {
      roleId: {
        type: Object,
      }
    },
    data() {
      return {
        editDialogVisible: false,
        ruleForm: {
          factoryName: '',
          unifiedSocialCode: '',
          adminName: '',
          adminPhone: '',
          adminPassword: '',
        },
        rules: {
          factoryName: [
            {required: true, message: '请输入企业名称', trigger: 'blur'},
          ],
          unifiedSocialCode: [
            {required: true, message: '请输入统一社会信用代码', trigger: 'blur'},
            { min: 3, max: 18, message: '请输入正确的统一社会信用代码', trigger: 'blur' }
          ],
          adminName: [
            {required: true, message: '请输入管理员名称', trigger: 'blur'},
          ],
          adminPhone: [
            {required: true, message: '请输入管理员电话', trigger: 'blur'},
            { min: 7, max: 11, message: '请输入正确的电话格式', trigger: 'blur' }
          ],
          adminPassword: [
            {required: true, message: '请输入管理员密码', trigger: 'blur'},
          ],
        },
      }
    },
    methods: {

      edit(scope){
        console.log(scope);
        this.editDialogVisible = true
        this.ruleForm = {
          factoryName: scope.factoryName,
          unifiedSocialCode: scope.unifiedSocialCode,
          adminName: scope.adminName,
          adminPhone: scope.adminPhone,
          adminPassword: scope.adminPassword,
        }
      },

      // 监听编辑角色的弹框的关闭事件
      editDialogClose() {
        this.$refs.ruleForm.resetFields();
        this.editDialogVisible=false

      },

      // 点击编辑角色弹框的“确认”按钮提交
      editRole() {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            editManufacturerRequest({
              ...this.ruleForm,
              factoryId: this.roleId.factoryId
            }).then(res=>{
                let result = res.data;
                if (result.code !== 200) {
                  return this.alertMessage(result.msg, 'error');
                }
                this.alertMessage('修改成功', 'success');
                // 隐藏弹窗
                this.editDialogVisible=false
                this.$refs.ruleForm.resetFields();
                this.$emit('plan-list');
              })
          } else {
            return false;
          }
        })
      }
    }
  }
</script>

<style scoped>
.editDialog {
  display: inline-block;
  margin-right: 5px;
}
.el-button--text{
  color: var(--theme_bg_color);
}
  .el-button:focus,
  .el-button:hover {
    border: 0px;
    border-color: #fff;
    color: var(--theme_bg_color);
  }
.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}
.el-button--primary:focus,.el-button--primary:hover {
    border-color: var(--theme_bg_color);
    color: #fff;
  }
</style>
