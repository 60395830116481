<template>
  <div style="height: 100%;">
    <!-- 卡片视图 -->
    <el-card style="height: 100%;overflow:auto;">
       <!-- 顶部面包屑导航 -->
    <breadcrumb-nav>
      <template v-slot:firstMenu>客户管理</template>
      <template v-slot:secondMenu>液厂企业管理</template>
    </breadcrumb-nav>
    <div style="fontSize: 20px;fontWeight: 400;color: #0a0b1a;">
        液厂企业管理
      </div>
      <div class="radioBox">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="液厂企业名称">
          <el-input autosize v-model="formInline.factoryName" placeholder="请输入液厂企业名称" @input="onSubmit"></el-input>
        </el-form-item>
      </el-form>
    </div>
      <div class="buttonBox">
        <manufacturer-add @plan-list='updateRoleList'/>
      </div>
      <!-- table表格区域 -->
      <manufacturer-table :reconciliation-list="goodsList" @plan-list='updateRoleList' />
      <div style="float: right;">
        <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryInfo.pageNo"
              :page-sizes="[10, 20, 30, 50]"
              :page-size="queryInfo.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
        </el-pagination>
      </div>
    </el-card>


  </div>
</template>

<script>
  import BreadcrumbNav from "../../common/BreadcrumbNav";
  import { getManufacturerListRequest } from "network/customer";
  import ManufacturerTable from './components/ManufacturerTable.vue';
  import ManufacturerAdd from './components/ManufacturerAdd.vue';


  export default {
    name: "List",
    components: {
        BreadcrumbNav,
        ManufacturerTable,
        ManufacturerAdd,
    },
    data() {
      return {
         radio1: '0',
        queryInfo: {
          pageNo: 1,
          pageSize: 10
        },
        formInline: {
            factoryName: '',
        },
        goodsList: [],
        total: 0,
      }
    },
    created() {
      this.getGoodsList();
    },
    methods: {
      onSubmit() {
          this.getGoodsList();
      },
      getGoodsList() {
        getManufacturerListRequest({
          page: this.queryInfo,
          factoryName:this.formInline.factoryName
        }).then(res => {
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取数据失败', 'error');
          }
          this.goodsList = result.data.list;
          this.total = result.data.total;
        });
      },

      // 每页显示的数据条数发送变化
      handleSizeChange(newSize) {
        this.queryInfo.pageNo = 1
        this.queryInfo.pageSize = newSize;
        this.getGoodsList();
      },

      // 当前页码发生变化
      handleCurrentChange(newPage) {
        this.queryInfo.pageNo = newPage;
        this.getGoodsList();
      },

      // 跳转到添加商品的界面
      goAddPage() {
        this.$router.push('/add');
      },
      // 监听子组件中发出的事件，重新获取角色列表
      updateRoleList(){
        this.getGoodsList()
      }
    }
  }
</script>

<style scoped>

.radioBox{
  margin-top: 20px;
  margin-bottom: 15px;
}
.buttonBox{
  display: flex;
}
.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}
</style>
