<!--
 * @Description: 
 * @Author: 
 * @Date: 2023-03-06 16:42:04
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-04-18 16:22:08
-->
<template>
  <el-table  :data="reconciliationList" border :header-cell-style="{color: '#848484', fontSize: '14px', height:'22px',  background: '#f7f7f7'}" :cell-style="{color: '#0a0b1a'}">
    <el-table-column prop="factoryName" label="液厂企业名称" ></el-table-column>
    <el-table-column prop="unifiedSocialCode" label="统一社会代码" ></el-table-column>
    <el-table-column prop="adminName" label="管理员" ></el-table-column>
    <el-table-column prop="adminPhone" label="联系电话" ></el-table-column>
    <el-table-column label="创建时间" prop="createDate" width="170px">
          <template slot-scope="scope">
            {{scope.row.createDate }}
          </template>
    </el-table-column>
    <el-table-column label="操作" width="200px">
      <template slot-scope="scope">
        <manufacturer-edit :role-id="scope.row" @plan-list="updateRoleList" />
        <manufacturer-remove :row-id="scope.row.factoryId" @plan-list="updateRoleList"/>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
// import InvoiceEdit from './TransportEdit'
import ManufacturerEdit from './ManufacturerEdit'
import ManufacturerRemove from './ManufacturerRemove.vue'


  export default {
    name: "ManufacturerTable",
    props: {
      reconciliationList: {
        type: Array,
        default() {
          return []
        }
      }
    },
    components: {
        ManufacturerEdit,
        ManufacturerRemove,
    },
    methods: {

      detailsPage() {
        this.$router.replace('/pages/biz/gas_factory/finance/bill/unapplied')
      },
      // 监听子组件中发出的事件，重新获取角色列表
      updateRoleList() {
        this.$emit('plan-list');
      }
    }
  }
</script>

<style scoped>

</style>
